import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store'
import { AuthLogin, AuthResponse } from '@/models'
import { environment } from '@/environments/environment'
import { eventbus, eventBusEvents } from '@fiizy/utils'
import auth from '@/utils/auth'

const bus = eventbus(environment.EVENT_CHANNEL || 'c432556660722ff93cefb5a932e550b7')
const { logOut, saveCredentials } = eventBusEvents

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'auth'
})
class AuthStore extends VuexModule {
  accessToken: string | null = null

  get getAccessToken(): string | null {
    return this.accessToken
  }

  @Mutation
  private SET_CREDENTIALS(accessToken: string | null) {
    this.accessToken = accessToken
  }

  @Action({ rawError: true, commit: 'SET_CREDENTIALS' })
  async isUserAuth(): Promise<string | null> {
    if (!(await auth.check())) {
      return null
    }

    const accessToken = auth.getAccessToken()

    if (accessToken) {
      bus.emit(saveCredentials, {})
      return accessToken
    }

    return null
  }

  @Action({ rawError: true })
  async authLogin({ channelHash, userName, password, grantType, authLinkHash }: AuthLogin): Promise<null | boolean> {
    if (!grantType) {
      return null
    }

    if (grantType === 'magic_link' && authLinkHash) {
      const magicLinkResponse = await auth.magicLinkLogin(authLinkHash)
      if (magicLinkResponse) {
        await store.dispatch('auth/saveCredentials', { accessToken: auth.getAccessToken() })
      }
      return magicLinkResponse
    }

    if (grantType === 'password' && userName && password) {
      const passwordLoginResponse = await auth.login({
        username: userName,
        password,
        channelHash
      })
      if (passwordLoginResponse) {
        await store.dispatch('auth/saveCredentials', { accessToken: auth.getAccessToken() })
      }
      return passwordLoginResponse
    }

    return null
  }

  @Action({ commit: 'SET_CREDENTIALS' })
  async saveCredentials(payload: AuthResponse): Promise<string> {
    bus.emit(saveCredentials, payload)
    return payload.accessToken
  }

  @Action
  async authLogout(): Promise<void> {
    auth.logout()
    bus.emit(logOut, {})
    store.commit('auth/SET_CREDENTIALS', null)
  }
}
const AuthModule = getModule(AuthStore, store)

export default AuthModule
